<template>
  <Stack id="layout" tag="main" direction="col" gap="none" class="min-h-screen">
    <slot />
  </Stack>
</template>

<script lang="ts" setup>
const { connect } = useSession();

await connect();
</script>
